var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#e0ffdd !important","min-height":"100vh","height":"auto"}},[_c('NoticeBar',{staticClass:"col-lg-12 col-12 mx-auto notice-bar font-weight-500",staticStyle:{"background":"linear-gradient(180deg, #2BC584 0%, #129156 100%)"},attrs:{"scrollable":"","text":"စိတ်ချယုံကြည် ZT Family - ဘောလုံးပွဲများကိုလည်း တိုက်ရိုက်ကြည့်ရှူနိုင်ပါသည်။"}}),_c('div',{staticClass:"px-3 mx-auto p-0 d-flex mt-2 pt-2"},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333","width":"100%","height":"auto"},attrs:{"id":"carousel-1","interval":4000,"controls":"","fade":""}},[_c('b-carousel-slide',{attrs:{"img-src":"https://yy24gld.sgp1.cdn.digitaloceanspaces.com/doubleT/zt_2.gif"}}),_c('b-carousel-slide',{attrs:{"img-src":"https://yy24gld.sgp1.cdn.digitaloceanspaces.com/doubleT/zt_3.gif"}})],1)],1),_c('NoticeBar',{staticClass:"col-lg-12 col-12 mx-auto notice-bar mt-3 mb-5 font-weight-500",staticStyle:{"background":"linear-gradient(180deg, #2BC584 0%, #129156 100%)"},attrs:{"scrollable":"","text":"မဂ်လာပါ မြန်မာငွေ ၁၅၀၀ကျပ်နဲ့ အကောင့်ဖွင့်ကစားရန်။ 09881335932"}}),_c('div',{staticClass:"justify-content-center cards-container-mobile pb-5"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1 pb-5"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/live')}}},[_vm._m(5)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1"},[_c('a',{attrs:{"href":"https://t.me/zawtunonlineagent"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"/build/assets/img/theme/telegram.png","alt":""}}),_c('span',{staticClass:"mx-auto text-center mt-2"},[_c('b',[_vm._v("Free Tips"),_c('br'),_vm._v("များရယူရန်။")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1 pr-0"},[_c('a',{attrs:{"href":"viber://contact?number=%2B959881335932"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"/build/assets/img/theme/viber.png","alt":""}}),_c('span',{staticClass:"mt-2 mx-auto text-center"},[_c('b',[_vm._v("လက်ခွဲရယူရန်။")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1 pr-0"},[_c('a',{attrs:{"href":"viber://contact?number=%2B959881335932"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"/build/assets/img/theme/viber.png","alt":""}}),_c('span',{staticClass:"mx-auto text-center mt-2"},[_c('b',[_vm._v("555Mix "),_c('br'),_vm._v("အကောင့်ဖွင့် ကစားရန်။")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1"},[_c('a',{attrs:{"href":"https://m.bet555mix.com/"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"/build/assets/img/theme/555mix.png","alt":""}}),_c('span',{staticClass:"mx-auto text-center mt-2"},[_c('b',[_vm._v("555Mix "),_c('br'),_vm._v(" တိုက်ရိုက်လင့်။")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-4 home-cards mb-1 pr-0"},[_c('a',{attrs:{"href":"https://www.bet555mix.com/android/555Mix_v10.0.0.apk"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"/build/assets/img/theme/555mix.png","alt":""}}),_c('span',{staticClass:"mx-auto text-center mt-2"},[_c('b',[_vm._v("555Mix "),_c('br'),_vm._v("App Down ရန်။")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end align-items-end flex-column position-relative mb-3"},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"70px","height":"70px","border-radius":"10px","box-shadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"},attrs:{"src":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR5-8zbtYlNsyhiIuxH1cyDkVIX5yohASFYw&s","alt":""}}),_c('span',{staticClass:"mx-auto text-center mt-2",staticStyle:{"color":"#6B1518"}},[_c('b',[_vm._v("Live ကြည့်မည်။")])])])
}]

export { render, staticRenderFns }