<template>
    <div class="app">
        <router-view></router-view>
        <!-- <audio autoplay loop src="http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/pause.wav"></audio> -->
    </div>
</template>
<script>
export default {
  name: 'app',
  methods: {
  },
  mounted() {
  },
};
</script>
