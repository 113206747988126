export const routes = [
  {
    path: '/',
    component: render('frontend/Index'),

    children: [
      {
        path: '',
        component: render('frontend/Home'),
        // meta: { auth: true },
      },
      {
        path: 'home',
        component: render('frontend/Home'),
        // meta: { auth: true },
      },
      {
        path: 'live',
        component: render('frontend/Live'),
      },
      {
        path: 'video-links',
        component: render('frontend/VideoLink'),
        
      },
    ],
  },
]

function render(template) {
  return require(`@/pages/${template}.vue`).default;
}
