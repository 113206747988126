import './registerServiceWorker'
import Vue from 'vue';
import VueRouter from 'vue-router';

import auth from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

import './plugins';
import './plugins/global-components';

import axios from 'axios';
import VueAxios from 'vue-axios';

// import store from './plugins/store';
import App from './App.vue';
// import { inject } from '@vercel/analytics';
import "./scss/style.scss";

import { routes } from './router/index';

Vue.use(VueAxios, axios);
Vue.use(VueRouter);

window.axios = axios;
window.moment = require('moment');

axios.defaults.baseURL = 'http://127.0.0.1:8001/api/';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const authToken = localStorage.getItem('token'); 
if(authToken){
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
});

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
      // loginData: {
      //   url: 'auth/login',
      //   method: 'POST',
      // },
      // registerData: {
      //   url: 'auth/register',
      //   method: 'POST',
      // },
      // fetchData: {
      //   url: 'auth/user',
      //   method: 'GET',
      // },
      // authRedirect: {
      //   path: '/auth/login',
      // },
  },
});

const app = new Vue({
  el: '#app',
  router,
  // store,
  render: (h) => h(App),
});
